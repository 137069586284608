import styled from 'styled-components'

import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
background: rgba(0, 0, 0, 0.8);
height: 80px;
/*margin-top: -80px;*/
display: flex;
justify-content: center;
align-items: center;
font-size: 1rem;
position: sticky;
top: 0%;
z-index: 10;

@media screen and (max-width: 960px){
    transition: 0.8 all ease
}
`

export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
z-index: 1;
width: 100%;
padding: 0 24px;
max-width: 1100px;
`

export const NavLogo = styled(LinkR)`
color: #fff;
justify-self: flex-start;
cursor: pointer;
font-size: 1.6rem;
display: flex;
align-items: center;
margin-left: 0px;
font-weight: bold;
font-family: Arial, Helvetica, sans-serif;
text-decoration: none;
`

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
margin-right: -22px;

@media screen and (max-width: 768px){
    display: none;
}
`

export const NavItem = styled.li`
height: 80px;
`

export const NavLinks = styled(LinkS)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
font-weight: bold;
font-family: Arial, Helvetica, sans-serif;
padding: 0 2rem;
height: 100%;
font-size: 1.2rem;
cursor: pointer;

&:active{
    border-bottom: 3px solid #01bf71;
}
`

export const NavBtn = styled.nav`
display: flex;
align-items: center;

@media screen and (max-width: 768px){
    display: none;
}
`

export const NavBtnLink = styled(LinkR)`
border-radius: 120px;
background: #01bf71;
white-space: nowrap;
padding: 10px 22px;
color: #010606;
font-size: 16px;
outline: none:
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
color: rgba(0, 0, 0, 0.8);rey;
font-family: Arial, Helvetica, sans-serif;
font-size: 1.5rem;
`