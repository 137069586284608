import { useState, useEffect } from "react";

const TypingEffect = ({ strings, CName }) => {
    const [currentStringIndex, setCurrentStringIndex] = useState(0);
    const [characters, setCharacters] = useState([]);
    const [currentCharacterIndex, setCurrentCharacterIndex] = useState(0);
    const [backtracking, setBacktracking] = useState(false);
    const [wordSwitchDelay, setWordSwitchDelay] = useState(false);

    const [characterDelay, setCharacterDelay] = useState(Math.floor(Math.random() * (500 - 50 + 1)) + 50);

    useEffect(() => {
      if (backtracking) {
        setCharacterDelay(67);
      } else{
        setCharacterDelay(Math.floor(Math.random() * (150 - 50 + 1)) + 50);
      }

      const interval = setInterval(() => {
        if (wordSwitchDelay) {
          setWordSwitchDelay(false);
          setCharacters([]);
          setCurrentCharacterIndex(0);
          setCurrentStringIndex((prevIndex) => (prevIndex + 1) % strings.length);
        } else if (backtracking) {
          const newCharacters = [...characters];
          newCharacters.pop();
          setCharacters(newCharacters);
  
          if (newCharacters.length === 0) {
            setBacktracking(false);
            setWordSwitchDelay(true);
          }
        } else {
          if (currentCharacterIndex < strings[currentStringIndex].length) {
            const newCharacters = [...characters, strings[currentStringIndex][currentCharacterIndex]];
            setCharacters(newCharacters);
            setCurrentCharacterIndex((prevIndex) => prevIndex + 1);
          } else {
            setBacktracking(true);
            setCurrentCharacterIndex(currentCharacterIndex - 1);
          }
        }
      }, characterDelay); // Adjust the interval time as needed (currently set to 200 milliseconds)
  
      return () => clearInterval(interval); // Clear interval on component unmount
    }, [backtracking, characters, currentCharacterIndex, currentStringIndex, strings, wordSwitchDelay]);
  
  
    return (
      <div>
        <p className="card-desc">{"‎" + characters.join('')}</p>
      </div>
    );
  };

export default TypingEffect