import React from 'react'

import {ContactContainer, ContactTextContainer, ContactText, ContactBtnLink, ContactBtnContainer} from './ContactElements'

import { FaGithub } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa6";

const ContactSection = () => {
  return (
    <>
        <ContactContainer id="Contact">
          <ContactTextContainer>
            <ContactText>
              Vidde 2023. <FaRegCopyright/>
            </ContactText>

            <ContactBtnContainer>
              <ContactBtnLink to="https://github.com/0xVidde">
                <FaGithub/>
              </ContactBtnLink>

              <ContactBtnLink to="https://twitter.com/Vidde19">
                <FaTwitter/>
              </ContactBtnLink>

              <ContactBtnLink to="https://www.youtube.com/channel/UCHvt7X1hBjoTpUzXEiSjzVw">
                <FaYoutube/>
              </ContactBtnLink>
            </ContactBtnContainer>
          </ContactTextContainer>
        </ContactContainer>
    </>
  )
}

export default ContactSection