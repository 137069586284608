import React from 'react'

import { Nav, NavbarContainer, NavLogo, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink } from './NavbarElement'

import { GiPresent } from "react-icons/gi";

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">vidde</NavLogo>
          
          <NavMenu>
            <NavItem>
              <NavLinks to="About" smooth={true} duration={1000}>About</NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks to="Projects" smooth={true} duration={1000}>Projects</NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks to="Contact" smooth={true} duration={1000}>Contact</NavLinks>
            </NavItem>
          </NavMenu>

          <NavBtn>
              <NavBtnLink to="https://soundcloud.com/djbarnhem/diagnoser-vol5">
                <GiPresent />
              </NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar