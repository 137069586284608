import Navbar from "../Components/Navbar/";
import { BrowserRouter as Router, } from 'react-router-dom'

import AboutSection from "../Components/AboutSection/index";
import ProjectsSection from "../Components/ProjectsSection/index";
import ContactSection from "../Components/ContactSection/index";

const Home = () => {
  return (
    <>
      <Router>
        <Navbar/>
        <AboutSection/>
        <ProjectsSection/>
        <ContactSection/>
      </Router>
    </>
  )
}

export default Home