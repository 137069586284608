import Home from "./Pages/Home";

function App() {
  return(
    <>
      <Home/>
    </>
  );
}

export default App;
