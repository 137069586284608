import styled from 'styled-components'

export const AboutContainer = styled.div`
background: #fff;
display:flex;
justify-content: center;
align-items: center;
padding: 0 30px;
height: 800px;
position: relative;
z-index = 1;
`

export const AboutBg = styled.div`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
`