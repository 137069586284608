import TypingEffect from "./TypingEffect";


function Card({ title, img, words }) {
    return (
        <div className="card">
            <img className="card-img" src={img} alt="profile picture" />
            <h2 className="card-title">{title}</h2>
            <TypingEffect strings={words} card-desc="card-desc"></TypingEffect>
        </div>
    );
}

export default Card