import styled from 'styled-components'

import { Link as LinkR } from 'react-router-dom'

export const ContactContainer = styled.div`
background: rgba(0, 0, 0, 0.8);
height: 400px;
display: flex;
justify-content: center;
align-items: center;
`;

export const ContactTextContainer = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 400px;
  width: 800px;
  display: flex;
  justify-content: center;
  position: relative; /* Make the container relative for absolute positioning */
`;

export const ContactText = styled.h1`
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  position: absolute; /* Position the text absolutely */
  z-index: 1; /* Ensure the text appears above other elements */
  font-size: 1.3rem;
`;

export const ContactBtnLink = styled(LinkR)`
border-radius: 15px;
background: #01bf71;
white-space: nowrap;
padding: 10px 22px;
color: #010606;
outline: none:
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
color: rgba(0, 0, 0, 0.8);rey;
font-family: Arial, Helvetica, sans-serif;

font-size: 1.5rem;

margin: 30px;
`

export const ContactBtnContainer = styled.div`
position: absolute;
bottom: 55px; /* Move the buttons down by 50px */
`;