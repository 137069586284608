import React from 'react'

import { AboutContainer, AboutBg } from './AboutElements'
import Card from "../Card";

import pfp from "../../Assets/8283-cat-chill.png"

const AboutSection = () => {
  return (
   <AboutContainer id="About">
    <AboutBg>
      <Card title="Vidde" img={pfp} words={[
          "Hobby programmer and student.", 
          "Abusing the compiler since 2017.",
          "IOCTL Professor", 
          "Python is detected...", 
          "we ballin", 
          "STATUS: UD", 
          "meow", 
          "yeah don't do that -vort",
          "RRRAAAHHH",
          "Tax Evader!!!",
          "Selling EAC bypass ∞£!",
          "Licensed VAC bypasser",
          "Cazz Glow ESP UD?",
          "mx > atv"
      ]}/>
    </AboutBg>
   </AboutContainer>
  )
}

export default AboutSection