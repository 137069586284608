import React from 'react'

import { ProjectsContainer, ProjectsCardContent,ProjectsCardImage, ProjectsCard, ProjectsCardTitle, ProjectsTitle, ProjectCardContainer, ProjectsCardDesc } from './ProjectsElements'

import ac_multi_hack_img from '../../Assets/ac_multi_hack.png'

const ProjectsElements = () => {
  return (
    <>
        <ProjectsContainer>
        <ProjectsTitle id="Projects">
          Projects
        </ProjectsTitle>


            <ProjectCardContainer>
              
              <ProjectsCard>
                <ProjectsCardImage src={ac_multi_hack_img}></ProjectsCardImage>
                <ProjectsCardContent>
                  <ProjectsCardTitle>Assault Cube Multi Hack</ProjectsCardTitle>
                  <ProjectsCardDesc>https://t.ly/aBei4 ← Github</ProjectsCardDesc>
                </ProjectsCardContent>
              </ProjectsCard>
            </ProjectCardContainer>
        </ProjectsContainer>
    </>
  )
}

export default ProjectsElements