import styled, {keyframes} from 'styled-components'

const cardWidth = 300 + 40;
const numCards = 3;

const totalCardsWidth = 30 + cardWidth * numCards;

const bordarRad = 10;

const ProjectsCardEaseIn = keyframes`
from {
    box-shadow:  5px 5px 5px rgba(0, 0, 0, 0.11);
    background-color: rgba(0, 0, 0, 0);
    transform: none;
    border-radius: 10%;
  }
  to {
    box-shadow:  20px 20px 20px rgba(0, 0, 0, 0.11);
    background-color: rgba(0, 0, 0, 0.02);
    transform: translateY(-10px) translateX(10px);
    border-radius: 15%;
`;

const ProjectsCardEaseOut = keyframes`
from {
    box-shadow:  20px 20px 20px rgba(0, 0, 0, 0.11);
    background-color: rgba(0, 0, 0, 0.02);
    transform: translateY(-10px) translateX(10px);
    border-radius: 15%;
  }
  to {
    box-shadow:  5px 5px 5px rgba(0, 0, 0, 0.11);
    background-color: rgba(0, 0, 0, 0);
    transform: none;
    border-radius: 10%;
  }
`;


export const ProjectsContainer = styled.div`
  background: #fff;
  height: 800px;
  position: relative;
  z-index: 1;
  text-align: center;
`;

export const ProjectsCard = styled.div`
    background-color: rgba(0, 0, 0, 0);
    width: 300px;
    height: 300px;

    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 20px;

  animation: cardEasyOut 0.5s ease backwards;
  
  border: 1px solid rgba(250, 250, 250, 1);
  border-color: rgba(0, 0, 0, 0.219);
  border-radius: 10%;
  box-shadow:  5px 5px 5px rgba(0, 0, 0, 0.11);

  animation: ${ProjectsCardEaseOut} 0.5s ease backwards;

  &:hover {
    animation: ${ProjectsCardEaseIn} 0.5s ease forwards;
  }

  margin-top: -50px;

  @media screen and (max-width: ${totalCardsWidth}px) {
    margin-bottom: 60px;
  }
`;

export const ProjectsCardContent = styled.div`

  padding: 20px;
  text-align: center; /* Center text content */

  transform: translateY(-85px);
`;


export const ProjectsCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit; /* Rounded corners, adjust as needed */
  transform: translateY(120px);
`

export const ProjectsCardTitle = styled.h2`
font-family: Arial, Helvetica, sans-serif;
margin: 0;
color: black;
font-size: 1.392rem;
text-align: center; /* Center the description text */

margin-top: 200px; /* Move title down by 20 pixels */
`


export const ProjectsCardDesc = styled.h2`
font-family: Arial, Helvetica, sans-serif;
color: rgba(0, 0, 0, 0.5);
text-overflow: ellipsis;
overflow: hidden;
font-size: 1rem;
white-space: nowrap;
text-overflow: ellipsis;
text-align: center; /* Center the description text */
`


export const ProjectsTitle = styled.h2`
font-family: Arial, Helvetica, sans-serif;
color: black;
bottom: 0;
left: 0;
padding: 130px;
font-size: 4rem;

@media screen and (max-width: ${totalCardsWidth}px) {
  transform: translateX(-50px);
}
`

export const ProjectCardContainer = styled.div`
background: #fff;
display: flex;
justify-content: center;
align-items: center;
position: relative;
z-index: 1;

@media screen and (max-width: ${totalCardsWidth}px) {
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
`